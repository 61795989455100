@tailwind base;

/* Write your own custom base styles here */

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Write your own custom component styles here */
.btn-blue {
  @apply bg-blue-500 text-white font-bold py-2 px-4 rounded;
}

.StripeElement {
  @apply block mt-2 mb-4 px-3 py-2 bg-gray-50 border border-gray-200;
}

.privacy-policy p {
  @apply mt-2;
}

.steps-nav {
  @apply flex flex-wrap px-6 list-decimal mb-8 justify-between;
}

.steps-nav li {
  @apply w-full mb-2;
}

.steps-nav li br {
  display: none;
}

.date-and-code {
  display: flex;
  margin-bottom: 8px;
}

.date-and-code > div:nth-child(1) {
  width: 55.715%;
}

.date-and-code > div:nth-child(2) {
  width: 45.719%;
}

.input-container {
  position: relative;
  display: flex;
  height: 40px;
}

.icon-container:last-child {
  right: 0;
}

.icon-container.payment-method {
  right: 0;
}

.input-container.card-number {
  margin-bottom: 8px;
}

.input-container.expiry-date {
  margin-right: 4px;
}

.input-container.cvv {
  margin-left: 4px;
}

.card-number-frame {
  padding-left: 40px;
}

.expiry-date-frame {
  padding-left: 40px;
}

.cvv-frame {
  padding-left: 40px;
}

div + button {
  margin-top: 8px;
}

.icon-container {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 26px;
  margin: 0 7px;
}

.icon-container.payment-method {
  transform: translateY(-50%) rotateY(90deg);
  transition: opacity 0.15s ease-out;
  opacity: 0;
  top: 50%;
}

.icon-container.payment-method.show {
  opacity: 1;
  transition: all 0.4s ease-out;
  transform: translateY(-50%) rotateY(0deg);
}

.icon-container.payment-method img {
  width: 100%;
}

[id$='-error'] {
  display: none;
}

.frame {
  opacity: 0;
}

.frame--activated {
  @apply block mt-2 mb-4 px-3 py-2 bg-white border border-gray-300;
}

.frame--activated.frame--invalid {
  @apply border-red-500;
}

/**
IE11-targeted optimisations
 */

_:-ms-fullscreen,
:root .icon-container {
  display: block;
}

_:-ms-fullscreen,
:root .icon-container img {
  top: 50%;
  -ms-transform: translateY(-50%);
  position: absolute;
}

_:-ms-fullscreen,
#icon-card-number,
_:-ms-fullscreen,
#icon-expiry-date,
_:-ms-fullscreen,
#icon-cvv {
  left: 7px;
}

#checkout-frames-card-number::-ms-clear {
  display: none;
}

iframe#controllerFrame {
  display: none !important;
}

.steps-nav li {
  width: 45%;
  font-size: 13px;
}

.steps-nav li.active a {
  font-weight: bold;
}

a.confirmation-link {
  color: var(--color-primary);
}

a.confirmation-link:hover {
  text-decoration: underline;
}

@media (min-width: 375px) {
  .steps-nav li {
    @apply w-1/2 text-sm;
  }
}

@media (min-width: 768px) {
  .steps-nav li {
    @apply w-1/3;
  }
}

@media (min-width: 1024px) {
  .steps-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 0 3rem;
    padding: 0;
  }

  .steps-nav li {
    position: relative;
    width: calc(33% + 1.2rem);
    padding-right: 2.8rem;
    margin-right: -2.8rem;
    overflow: hidden;
    margin-bottom: 3px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .steps-nav li br {
    display: block;
  }

  .steps-nav li:last-child {
    margin-right: 0;
  }

  .steps-nav li:last-child a:before {
    display: none;
  }

  .steps-nav li:after {
    content: '';
    position: absolute;
    z-index: 2;
    border-left: 30px solid #e0e4e9;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    right: 15px;
    top: 0;
  }

  .steps-nav li.active:after {
    border-left-color: var(--color-primary);
  }

  .steps-nav li.active a {
    background-color: var(--color-primary);
    color: #fff;
    font-weight: normal;
  }

  .steps-nav a {
    display: block;
    font-size: 1rem;
    line-height: 1;
    font-weight: 500;
    text-align: center;
    cursor: default;
    color: #464c4e;
    padding: 5px 0 5px 30px;
    background-color: #e0e4e9;
    overflow: hidden;
    text-transform: capitalize;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .steps-nav a:after {
    content: '';
    display: block;
    width: 0;
    min-height: 50px;
  }

  .steps-nav a:before {
    content: '';
    position: absolute;
    z-index: 1;
    border-left: 35px solid #fff;
    border-top: 35px solid transparent;
    border-bottom: 35px solid transparent;
    right: 9px;
    top: -4px;
  }
}

@media (max-width: 1024px) {
  .v4-header-menu,
  .v4-footer-menu {
    display: none;
  }
  .v4-logo {
    margin: 0 auto;
  }
}

.check::before {
  background-image: url('/images/check.png');
  background-size: 1rem;
  margin-right: 0.7rem;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  content: '';
}

/* landing spinner */
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--color-primary);
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* end spinner */

.landing-wrapper {
  opacity: 0;
}

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */

#rocketpay_remember {
  display: none;
}

#rg-payment-form label {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}

#rg-payment-form input {
  height: 45px;
  font-size: 16px !important;
}

#rg-payment-form select {
  height: 45px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff
    url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23000'><polygon points='0,0 100,0 50,50'/></svg>")
    no-repeat;
  background-size: 10px;
  background-position: calc(100% - 15px) center;
}

#rg_ErrorMessage {
  color: red;
}

#rg-payment-form #divcvv {
  order: 3;
}

#rg-payment-form #divexpire option {
  font-size: 16px;
}

#rg-payment-form #divexpire select {
  font-size: 16px !important;
}

#rg-payment-form #rg-card-fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#rg-payment-form #divemail,
#rg-payment-form #divcardno {
  flex-basis: 100%;
}

#rg-payment-form #divcvv,
#rg-payment-form #divexpire {
  flex-basis: 48%;
}

#rg-payment-form #divexpire p {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#rg-payment-form #divexpire label {
  flex-basis: 100%;
  margin-bottom: 3px;
}

#rg-payment-form #divexpire span {
  flex-basis: 48%;
}

@media only screen and (max-width: 768px) {
  #rg-payment-form #divcvv {
    flex-basis: 30%;
  }
  #rg-payment-form #divexpire {
    flex-basis: 66%;
  }
}

@media only screen and (max-width: 480px) {
  #rg-payment-form #divcvv {
    flex-basis: 100%;
  }
  #rg-payment-form #divexpire {
    flex-basis: 100%;
  }
}
